html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

.DrawerContainer_drawerContainer__251xW {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: -webkit-flex;
    display: flex;
    background-color: #efefef;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.DrawerContainer_drawerContent__1cdNC {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
}

@media (min-width: 1024px) {
    .DrawerContainer_drawerContent__1cdNC {
        width: 50%;
    }
}

@media (min-width: 1536px) {
    .DrawerContainer_drawerContent__1cdNC {
        width: 33%;
    }
}

@media (min-width: 2048px) {
    .DrawerContainer_drawerContent__1cdNC {
        width: 25%;
    }
}

@media (max-width: 1023px) {
    .DrawerContainer_drawerContainer__251xW > :not(:last-child) {
        display: none;
    }
}

@media (max-width: 1535px) {
    .DrawerContainer_drawerContainer__251xW > :not(:nth-last-child(-n+2)) {
        display: none;
    }
}

@media (max-width: 2047px) {
    .DrawerContainer_drawerContainer__251xW > :not(:nth-last-child(-n+3)) {
        display: none;
    }
}

@media (max-width: 2559px) {
    .DrawerContainer_drawerContainer__251xW > :not(:nth-last-child(-n+4)) {
        display: none;
    }
}

.App_wrapper__2cVWB {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.App_container__3WLqs {
    position: relative;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.App_loader__2YRt- {
    text-align: center;
    padding-top: 20%;
}

