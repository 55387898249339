.drawerContainer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    background-color: #efefef;
    align-items: flex-start;
}

.drawerContent {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
}

@media (min-width: 1024px) {
    .drawerContent {
        width: 50%;
    }
}

@media (min-width: 1536px) {
    .drawerContent {
        width: 33%;
    }
}

@media (min-width: 2048px) {
    .drawerContent {
        width: 25%;
    }
}

@media (max-width: 1023px) {
    .drawerContainer > :not(:last-child) {
        display: none;
    }
}

@media (max-width: 1535px) {
    .drawerContainer > :not(:nth-last-child(-n+2)) {
        display: none;
    }
}

@media (max-width: 2047px) {
    .drawerContainer > :not(:nth-last-child(-n+3)) {
        display: none;
    }
}

@media (max-width: 2559px) {
    .drawerContainer > :not(:nth-last-child(-n+4)) {
        display: none;
    }
}
