.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.container {
    position: relative;
    flex-grow: 1;
}

.loader {
    text-align: center;
    padding-top: 20%;
}
